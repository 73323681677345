<template>
  <ion-page>
    <Header title="Profil Akun" />
    <ion-content :fullscreen="true">
      <div class="container">
        <label for="phone">Name</label>
        <ion-input type="text" class="input" v-model="user.name"></ion-input>
        <label for="phone">Email</label>
        <ion-input type="text" class="input" v-model="user.email" :readonly="this.$auth.verified"></ion-input>

        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="save">Simpan</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, IonButton, toastController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    Header
  },
  data () {
    return {
      user: {
        name: null,
        email: null
      }
    }
  },
  created (){
    this.user = {
      name: this.$auth.name,
      email: this.$auth.email
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  },
  methods: {
    async save () {
      let res = await this.$api.post('/api/profile', this.user);
      if(! res.error) {
        this.$store.commit('auth/setUser', res.data)
        let toast = await toastController.create({
            message: 'sucess',
            duration: 2000,
            color: 'success',
          })
        return toast.present()
      }
    }
  }
})
</script>

<style>

</style>
